<script setup>
import { ref } from "vue";

const mobileNavIsOpened = ref(false);
const navLinks = [
  {
    label: "About Us",
    url: "/#about",
  },
  {
    label: "Projects",
    url: "/#projects",
  },
  {
    label: "Features",
    url: "/#features",
  },
  {
    label: "Vacancies",
    url: "/#vacancies",
  },
  {
    label: "Contact Us",
    url: "/#contact-us",
  },
];

function openNavBar() {
  document.body.classList.add("no-scroll");
  mobileNavIsOpened.value = true;
}

function closeNavBar() {
  document.body.classList.remove("no-scroll");
  mobileNavIsOpened.value = false;
}
</script>

<template>
  <nav class="nav-container">
    <div class="content">
      <RouterLink class="logo" to="/">
        <img src="@/assets/logo.svg" alt="DEXIO logo" width="134" height="38" />
      </RouterLink>

      <div class="navigation-body">
        <div
          class="burger"
          :class="{ open: mobileNavIsOpened }"
          @click="mobileNavIsOpened ? closeNavBar() : openNavBar()"
        >
          <span class="burger-line top"></span>
          <span class="burger-line mid"></span>
          <span class="burger-line bot"></span>
        </div>
        <ul class="nav-links" :class="{ show: mobileNavIsOpened }">
          <li v-for="item in navLinks" :key="item.url" @click="closeNavBar">
            <!-- <RouterLink class="link-item" :to="item.url"> -->
            <a class="link-item" :href="item.url">
              {{ item.label }}
            </a>
            <!-- </RouterLink> -->
          </li>
        </ul>
        <div
          class="overlay"
          :class="{ show: mobileNavIsOpened }"
          @click="closeNavBar"
        ></div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.nav-container {
  position: sticky;
  z-index: 10;
  top: 0;
  padding: 0 1.6rem;
  background-color: black;
  background-image: url("@/assets/home-header-bg-320.webp");
  background-repeat: no-repeat;
  background-position: top right;
  height: 8rem;
  display: flex;
  align-items: center;

  @include phone-md() {
    height: 10rem;
    background-image: url("@/assets/home-header-bg-428.webp");
  }

  @include tablet() {
    height: 12rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background-image: url("@/assets/home-header-bg-768.webp");
  }

  @include desktop() {
    background-image: url("@/assets/home-header-bg-1440.webp");
    background-size: 100vw auto;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 114.6rem;
    margin: 0 auto;
  }

  .logo {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      max-width: 11rem;
      height: auto;

      @include phone-md() {
        max-width: 13.4rem;
      }
    }
  }

  .burger {
    width: 3.6rem;
    height: 1.8rem;
    position: relative;
    cursor: pointer;
    z-index: 3;

    @include desktop() {
      display: none;
    }

    .burger-line {
      width: 100%;
      border-radius: 0.1rem;
      border: 0.1rem solid white;
      position: absolute;
    }

    .top {
      top: 0;
      transition: transform 0.1s linear, top 0.1s 0.1s linear;
    }

    .mid {
      top: calc(50% - 0.1rem);
      opacity: 100;
      transition: opacity 0s 0.1s;
    }

    .bot {
      bottom: 0;
      transition: bottom 0.1s 0.1s linear, transform 0.1s linear;
    }
  }

  .open {
    .top {
      top: calc(50% - 0.1rem);
      transform: rotate(-45deg);
      transition: transform 0.1s 0.1s linear, top 0.1s linear;
    }

    .mid {
      opacity: 0;
    }

    .bot {
      bottom: calc(50% - 0.1rem);
      transform: rotate(45deg);
      transition: bottom 0.1s linear, transform 0.1s 0.1s linear;
    }
  }

  .nav-links {
    display: grid;
    align-content: start;
    justify-items: center;
    box-sizing: border-box;
    overflow-y: auto;
    row-gap: 3rem;
    list-style: none;
    padding: 12rem 2.4rem;
    margin: 0;
    position: fixed;
    top: 0;
    left: 100vw;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    z-index: 2;
    transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-image: url("@/assets/home-header-bg-320-tp.webp");
    background-repeat: no-repeat;
    background-position: top right;

    @include phone-md() {
      gap: 5.2rem;
      padding: 14rem 2.4rem;
      background-image: url("@/assets/home-header-bg-428-tp.webp");
    }

    @include tablet() {
      row-gap: 4rem;
      background-image: url("@/assets/home-header-bg-768-tp.webp");
    }

    @include desktop() {
      position: static;
      grid-auto-flow: column;
      column-gap: 4rem;
      background: none;
      width: auto;
      height: auto;
      padding: 0;
    }

    .link-item {
      color: white;
      font-size: 2.1rem;
      line-height: 2.9rem;
      font-weight: 600;
      text-decoration: none;

      @include phone-md() {
        font-size: 3.2rem;
        line-height: 4.2rem;
      }

      @include desktop() {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 100vw;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    z-index: 1;
    transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    @include desktop() {
      display: none;
    }
  }

  .show {
    left: 0;
    width: 100vw;

    @include desktop() {
      width: auto;
    }
  }
}
</style>
