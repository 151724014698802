import { createWebHistory, createRouter } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/eula',
    name: 'eula',
    component: () => import('@/views/EULA.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import('@/views/TermsOfUse.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import("@/views/PrivacyPolicy.vue")
  }
]

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            top: 0,
            behavior: 'smooth'
          }), 0
        })
      })
    } else {
      return { top: 0, behavior: 'instant' }
    }
  }
})
